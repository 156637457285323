<template lang="pug">
//- a.listing()
.listing(
  @click='$router.push({ name: "SinglePage", params: { id: listing.link } })',
  :is='$store.getters.desktop ? "div" : "a"',
  :href='`${$frontname}listing/${listing.link}`'
)
  .listing-image.zoom: img(:src='imgUrl()', alt)
  .listing-title {{ listing.title }}
  .listing-location {{ listing.address }}
  .listing-price {{ listing.priceCondition == "from" ? listing.priceCondition : "" }} {{ listing.priceValue | toCurrency }}
  .listing-props
    .listing-prop(v-if='listing && listing.beds')
      SvgComponent(name='bed')
      span {{ listing.beds }}
    .listing-prop(v-if='listing && listing.baths')
      SvgComponent(name='bath')
      span {{ listing.baths }}{{ halfBathRender() }}
</template>

<script>
  import SvgComponent from '@/components/SvgComponent';

  export default {
    components: { SvgComponent },
    props: {
      listing: Object,
    },
    methods: {
      imgUrl() {
        return this.$hostname + (this.listing.image.formats.medium?.url || this.listing.image.url);
      },
      halfBathRender() {
        if (!this.listing.halfbaths) {
          return '';
        }
        if (this.listing.halfbaths == 1) {
          return '.5';
        }
        if (this.listing.halfbaths > 1) {
          return ` + ${this.listing.halfbaths}half`;
        }
      },
    },
  };
</script>

<style lang="scss">
  .listing {
    cursor: pointer;
    line-height: 36.54px;
    font-size: 20px;
    @include middle {
      line-height: 1.2em;
    }
    @include tablet {
      line-height: 1.5em;
    }
    &-image {
      position: relative;

      height: 386px;
      width: 100%;
      @include middle {
        height: 320px;
        // width: 320px;
      }
      @include small {
        height: 250px;
        // width: 250px;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
      user-select: none;
      @include tablet {
        height: 360px;
        width: 100%;
        // width: 360px;
      }
    }
    &-title {
      margin-top: 20px;
      font-size: 30px;
      @include middle {
        font-size: 18px;
      }
      @include tablet {
        font-size: 24px;

        margin-top: 16px;
      }
    }
    &-location {
      font-weight: 300;
      font-size: 30px;
      @include middle {
        font-size: 18px;
      }
      @include tablet {
        font-size: 24px;
      }
    }
    &-price {
      font-weight: 600;
      margin-top: 10px;
      font-size: 20px;
      line-height: 24px;
      @include middle {
        font-size: 16px;
      }

      @include tablet {
        font-size: 20px;
      }
    }
    &-props {
      display: flex;
      @include gap(40px, row);
      margin-top: 10px;
    }
    &-prop {
      display: flex;
      align-items: center;
      @include gap(10px, row);
    }
    &-info > * {
      margin-left: 16px;
    }
  }
  .listing-title,
  .listing-location,
  .listing-props,
  .listing-price {
    @include phone {
      margin-left: 16px;
    }
  }

  .singleAlso {
    .listing-title,
    .listing-location,
    .listing-props,
    .listing-price {
      margin-left: 20px;
      @include tablet {
        margin-left: 16px;
      }
    }
  }
  .singleAlso .listing-image {
    height: 386px;
    width: 386px;
    @include middle {
      height: 320px;
      width: 320px;
    }
    @include small {
      height: 250px;
      width: 250px;
    }
    @include tablet {
      height: 360px;
      width: 100%;
    }
  }
</style>
