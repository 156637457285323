<template>
  <div class="vl-calendar">
    <span class="vl-calendar__arrow vl-calendar__arrow--back" @click="moveBack()">
      <svg width="8" height="14" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.64706 1L1 7L6.64706 13"
          stroke="currentColor"
          stroke-width="1.41176"
          stroke-linejoin="round"
          fill="none"
        />
      </svg>
    </span>

    <span class="vl-calendar__arrow vl-calendar__arrow--forward" @click="moveForward()">
      <svg width="8" height="14" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.999914 13L6.64697 7L0.999915 1"
          stroke="currentColor"
          stroke-width="1.41176"
          stroke-linejoin="round"
          fill="none"
        />
      </svg>
    </span>

    <vl-calendar-month
      class="vl-calendar__month"
      :month="currentMonthMonth"
      :year="currentMonthYear"
      :today="today"
      :is-selected="isSelected"
      :is-disabled="isDisabled"
      :is-ranged="isRanged"
      @input="(date) => $emit('input', date)"
      @hover="(date) => $emit('hover', date)"
    />

    <vl-calendar-month
      class="vl-calendar__month"
      :month="nextMonthMonth"
      :year="nextMonthYear"
      :today="today"
      :is-selected="isSelected"
      :is-disabled="isDisabled"
      :is-ranged="isRanged"
      @input="(date) => $emit('input', date)"
      @hover="(date) => $emit('hover', date)"
    />
  </div>
</template>

<script>
  import VlCalendarMonth from './vl-calendar-month';
  import { getToday, formatDate } from '../utils/DatesUtils';

  export default {
    name: 'VlCalendar',
    components: {
      VlCalendarMonth,
    },

    props: {
      isSelected: Function,
      isDisabled: Function,
      isRanged: Function,
    },

    data() {
      const today = getToday();
      return {
        currentMonthMonth: today.getMonth(),
        currentMonthYear: today.getFullYear(),
        today: formatDate(today.getDate(), today.getMonth(), today.getFullYear()),
      };
    },

    computed: {
      nextMonthMonth() {
        return this.currentMonthMonth === 11 ? 0 : this.currentMonthMonth + 1;
      },

      nextMonthYear() {
        return this.currentMonthMonth === 11 ? this.currentMonthYear + 1 : this.currentMonthYear;
      },
    },

    methods: {
      moveBack() {
        if (this.currentMonthMonth === 0) {
          this.currentMonthMonth = 11;
          this.currentMonthYear--;
        } else {
          this.currentMonthMonth--;
        }
      },

      moveForward() {
        if (this.currentMonthMonth === 11) {
          this.currentMonthMonth = 0;
          this.currentMonthYear++;
        } else {
          this.currentMonthMonth++;
        }
      },
    },
  };
</script>
