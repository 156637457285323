<template>
  <div class="vl-calendar-month">
    <!-- {{ hoveredDate }} -->
    <div class="vl-calendar-month__title">{{ monthName }}</div>
    <div class="vl-flex">
      <span class="vl-calendar-month__week-day"> Su </span>
      <span class="vl-calendar-month__week-day"> Mo </span>
      <span class="vl-calendar-month__week-day"> Tu </span>
      <span class="vl-calendar-month__week-day"> We </span>
      <span class="vl-calendar-month__week-day"> Th </span>
      <span class="vl-calendar-month__week-day"> Fr </span>
      <span class="vl-calendar-month__week-day"> Sa </span>
    </div>

    <div class="vl-flex">
      <span
        v-for="day in days"
        :key="day"
        @click="$emit('input', getDate(day))"
        class="vl-calendar-month__day"
        :class="calculateClasses(day)"
        @mouseover="hover(day)"
        @mouseleave="hover(null)"
      >
        {{ day }}
      </span>
    </div>
  </div>
</template>

<script>
  import { createRange } from '../utils/CollectionUtils';
  import { countDays, formatDate, getMonthName } from '../utils/DatesUtils';

  export default {
    name: 'VlCalendarMonth',
    props: {
      month: Number,
      year: Number,
      isSelected: Function,
      isDisabled: Function,
      isRanged: Function,
      today: String,
    },

    computed: {
      monthName() {
        return getMonthName(this.month);
      },

      days() {
        return createRange(1, countDays(this.month, this.year));
      },
    },

    methods: {
      hover(day) {
        this.$emit('hover', day ? formatDate(day, this.month, this.year) : null);
      },

      getDate(day) {
        return formatDate(day, this.month, this.year);
      },

      calculateClasses(day) {
        const classes = [];
        if (day === 1) {
          let offset = (new Date(this.year, this.month, 2).getDay() + 6) % 7;
          if (offset > 0) {
            classes.push(`vl-calendar-month__day--offset-${offset}`);
          }
        }

        const date = this.getDate(day);

        if (this.isRanged(date)) {
          classes.push('ranged');
        }
        // if (!this.isSelected(day)) {
        //   classes.push('today');
        // }
        if (this.isSelected && this.isSelected(date)) {
          classes.push('selected');

          if (!this.isSelected(this.getDate(day - 1))) {
            classes.push('selected--first');
          }

          if (!this.isSelected(this.getDate(day + 1))) {
            classes.push('selected--last');
          }
        }

        if (date < this.today || (this.isDisabled && this.isDisabled(date))) {
          classes.push('disabled');

          if (!this.isDisabled(this.getDate(day - 1))) {
            classes.push('disabled--first');
          }

          if (!this.isDisabled(this.getDate(day + 1))) {
            classes.push('disabled--last');
          }
        }

        if (date == this.today) {
          classes.push('today');
        }

        return classes;
      },
    },
  };
</script>
